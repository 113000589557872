<template>
  <!-- *** LIST VIEW *** -->
  <EuiContainer>
    <confirmation-dialog v-if="isOpenConfirmationDialogOpen"
      :title="$t('settings.groups.delete.title')"
      :confirmText="$t('settings.groups.delete.confirmText')"
      :cancelText="$t('settings.groups.delete.cancelText')"
      :type="'danger'"
      @cancel="onCancel"
      @confirm="onConfirm"
    >
      <p v-html="$t('settings.groups.delete.content' , { 'group': sanitizedGroup })"/>
    </confirmation-dialog>
    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <div class="flex items-center">
            <EuiHeading :level="1" bold>{{ $t('settings.index.aside-menu.item.groups') }}</EuiHeading>
            <div class="flex flex-1 justify-end">
              <EuiButton variant="raised" color="primary" @click="$router.push({ name: 'settings.groups.create' })">
                {{ $t('settings.groups.button.create') }}
              </EuiButton>
            </div>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('settings.groups.title')">
            <EuiGrid>
              <EuiGridRow>
                <EuiGridColumn>
                  <span class="font-bold">
                    <template>{{ $tc('settings.groups.nbGroups', totalCount, { count: totalCount }) }}</template>
                  </span>
                  <div v-if="selected.length">
                    <span  class="font-bold">{{ $tc('settings.groups.selected', selected.length) }}</span>
                    <EuiButton iconOnly size="small" color="primary" @click="deleteSelected" :disabled="isDefaultGroupSelected">
                      <EuiIcon name="delete"/>
                    </EuiButton>
                  </div>
                </EuiGridColumn>
                <EuiGridColumn>
                  <EuiInput class="eui-m-cardFilter search" :placeholder="$t('searchBar.placeholder')"
                            v-model.trim="searchInput"
                            @keyup.enter="searchGroupBy()">
                    <template #endEnhancer>
                      <div class="eui-m-cardFilter__clear">
                        <template v-if="searchInput && searchInput.length > 0">
                          <EuiButton iconOnly size="small" color="primary" @click.stop="onClear">
                            <EuiIcon name="close"/>
                          </EuiButton>
                          <div class="eui-m-cardFilter__separator"></div>
                        </template>
                      </div>
                      <EuiButton iconOnly color="primary" size="small" @click.stop="searchGroupBy()">
                        <EuiIcon name="search"/>
                      </EuiButton>
                    </template>
                  </EuiInput>
                </EuiGridColumn>
              </EuiGridRow>
            </EuiGrid>
            <div v-show="this.filters.length > 0">
              <EuiDivider secondary/>
              <div class="eui-u-flex eui-u-items-center eui-u-ml-6">
                <EuiHeading class='eui-u-text-blue-grey eui-u-my-2' :level="3" bold>{{ $t('filterBar.title')}}</EuiHeading>
                <EuiIcon class='eui-u-text-blue-grey eui-u-ml-2' name='Filter2' size='medium'/>
                <EuiChip v-for="filter in filters"
                         closable
                         @click:close="removeFilter(filter)"
                         :key="filter.name"
                         class="eui-u-text-white eui-u-ml-4">
                  <template #text>
                    <span class="eui-u-font-bold eui-u-text-white">{{ $t(`filterBar.chips.title.${filter.name}`) }}:</span>
                    <span class="eui-u-text-white">{{ filter.value }}</span>
                  </template>
                </EuiChip>
                <EuiChip :text="$t('filterBar.button.reset')"
                         clickable outlined
                         class="eui-u-ml-4"
                         @click:chip="resetFilters"/>
              </div>
            </div>
            <EuiDivider secondary/>
            <EuiTable v-if="groups.length > 0">
              <EuiTableHeader>
                <EuiTableRow>
                  <!-- checkbox -->
                  <EuiTableHead dataType="input">
                    <EuiCheckbox v-model="selectAll"/>
                  </EuiTableHead>
                  <!-- group name USING SUBMENU : uncomment this if you need to add another option to the SubMenu
                  <EuiTableHead @onSortClick="onSortClick($event, 'token')">
                    <div class="eui-u-flex">
                      {{ header.name }}
                      <SubMenu :field="header.field"
                               :name="header.name"
                               :filterType="header.filter"
                               :moveColumns="false"
                               @addFilter="addFilter($event, computeFilter, searchGroup)"
                      />
                    </div>
                  </EuiTableHead>-->
                  <EuiTableHead class="pt-0 pb-0">
                    {{ header.name }}
                    <EuiDropdownMenu ref="ddNameFilter" :closeOnClickInside="false">
                      <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                                     :buttonText="$t('cardFilter.button.filter')"
                                     v-model="availableFilters.name.searchFieldValue"
                                     @click:search="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                                     @enter:searchBar="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                                     @click:filter="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()">
                      </EuiCardFilter>
                      <template #anchor><EuiButton iconOnly><EuiIcon name="more_vert" color="primary"/></EuiButton></template>
                    </EuiDropdownMenu>
                  </EuiTableHead>
                  <!-- options -->
                  <EuiTableHead></EuiTableHead>
                </EuiTableRow>
              </EuiTableHeader>
              <EuiTableBody>
                <!-- table row -->
                <EuiTableRow v-for="item in groups" :key="item.id" @click.native="goToEditPage(item.id)">
                  <!-- checkbox -->
                  <EuiTableCell dataType="input">
                    <EuiCheckbox :value="item" v-model="selected"/>
                  </EuiTableCell>
                  <!-- group name -->
                  <EuiTableCell>{{ item.name }}</EuiTableCell>
                  <EuiTableCell dataType="button">
                    <EuiDropdownMenu v-if="item.isDefault !== '1'">
                      <template v-slot:anchor>
                        <EuiButton iconOnly color="primary" size="small" @click.stop>
                          <EuiIcon name="more_vert"/>
                        </EuiButton>
                      </template>
                      <EuiList>
                        <EuiListItem :button="true" @click.stop="goToEditPage(item.id)">{{ $t('button.edit') }}</EuiListItem>
                        <EuiListItem :button="true" @click.stop="openDeleteConfirmationDialog(item)">
                          {{ $t('button.delete') }}
                        </EuiListItem>
                      </EuiList>
                    </EuiDropdownMenu>
                    <EuiButton iconOnly color="primary" size="small" v-else @click.stop="goToEditPage(item.id)">
                      <EuiIcon name="pen"/>
                    </EuiButton>
                  </EuiTableCell>
                </EuiTableRow>
              </EuiTableBody>
            </EuiTable>
            <EuiPagination v-if="groups.length"
                           :value="pagination.current"
                           :totalPages="pagination.totalPages"
                           :paginationText="$t('pagination.text')"
                           @change="goToPage($event)"/>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import tableFilters from '/src/mixins/tableFilters';
import DOMpurify from 'dompurify';
// import confirmationDialog from '@/pages/settings/dialog/confirmation';

export default {
  /**
   * The name of the page.
   */
  name: 'ManageGroups',

  /**
   * The components that the page can use.
   */
  components: {
    // SubMenu: () => import(/* webpackPrefetch: true */ '@/components/table/SubMenu'), uncomment if needed
    confirmationDialog: () => import(/* webpackPrefetch: true */ '@/pages/settings/dialog/confirmation'),
    // FiltersBar: () => import(/* webpackPrefetch: true */ '@/components/table/FiltersBar.vue'), uncomment if needed
  },

  /**
   * The mixins the component has access to
   */
  mixins: [tableFilters],

  /**
   * The data the page can use.
   *
   * @returns {Object} The data.
   */
  data() {
    return {
      header: { name: this.$i18n.t('settings.groups.table.group'), field: 'name', filter: ['sortable'] },
      allSelected: false,
      selected: [],
      groups: [],
      itemToDelete: null,
      filters: [],
      availableFilters: {
        name: {type: 'text', name: 'name', value: '', searchParamName: 'token', searchFieldValue: ''},
      },
      isOpenConfirmationDialogOpen: false,
      searchParams: {
        orders: [{ fieldName: 'name', orderBy: 'ASC' }],
        params: [],
        offset: 0,
        limit: 20,
      },
      searchInput: '',
      totalCount: null,
      currentPage: 1,
      totalPages: 1,
    };
  },

  /**
   * The computed properties the page can use.
   */
  computed: {
    ...mapState({
      pagination: (state) => state.ModuleEdocSign.settingsGroups.pagination,
    }),
    sanitizedGroup() {
      return DOMpurify.sanitize(this.itemToDelete.name);
    },
    isDefaultGroupSelected() {
      return this.selected.findIndex(item => item.isDefault === '1') > -1;
    },
    selectAll: {
      get() {
        return this.groups ? this.selected.length === this.groups.length : false;
      },
      set(value) {
        const selectedAll = [];

        if (value) {
          this.groups.forEach((group) => {
            selectedAll.push(group);
          });
        }
        this.selected = selectedAll;
      },
    },
  },

  /**
   * The methods the page can use.
   */
  methods: {
    ...mapActions({
      search: 'ModuleEdocSign/settingsGroups/search',
      findAll: 'ModuleEdocSign/settingsGroups/findAll',
      destroy: 'ModuleEdocSign/settingsGroups/destroy',
    }),
    computeFilter() {
      this.resetPagination();
      this.filters.forEach((filter) => {
        filter.searchTags.forEach((searchTag, index) => {
          this.searchParams.params[index] = { paramName: 'token', paramValue: searchTag };
        });
        if (filter.sort.length > 0) {
          this.searchParams.orders[0] =
            {
              fieldName: filter.field,
              orderBy: filter.sort[0],
            };
        }
      });
    },
    deleteFilterToCompute(field) {
      this.deleteFilter(field, this.clearSearchParamsFromFilter, this.searchGroup());
    },
    updateFilterToCompute(newFilters) {
      this.updateFilter(newFilters, this.computeFilter, this.searchGroup());
    },
    clearSearchParamsFromFilter(filter) {
      if (filter.sort.length > 0) {
        this.searchParams.order = { field: 'name', sens: 'ASC' };
      }
      this.searchParams.params.splice(this.searchParams.params.find(param => param.paramValue === filter.searchTags[0]), 1);
    },
    onSortClick(orderBy, fieldName) {
      this.searchParams.orders = [{ fieldName, orderBy }];
      this.searchGroup();
    },
    fetchGroups() {
      this.searchParams.limit = this.pagination.limit;
      return this.findAll(this.searchParams).then((response) => {
        this.totalCount = response.nbGroups;
        this.groups = response.groups;
        this.totalPages = this.pagination.totalPages;
      });
    },
    openDeleteConfirmationDialog(item) {
      this.itemToDelete = item;
      this.isOpenConfirmationDialogOpen = true;
    },
    onCancel() {
      this.itemToDelete = null;
      this.isOpenConfirmationDialogOpen = false;
    },
    onConfirm() {
      this.destroy(this.itemToDelete.id).then(() => {
        this.onCancel();
        this.fetchGroups();
      });
    },
    itemsPerPageChange(number) {
      this.searchParams.limit = number;
      this.searchParams.offset = 0;
      this.currentPage = 1;
      this.searchGroup();
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.searchGroup(this.searchParams);
    },
    goToFirstPage() {
      this.searchParams.offset = 0;
      this.searchGroup();
    },
    goToPrevPage() {
      this.searchParams.offset -= this.searchParams.limit;
      this.searchGroup();
    },
    goToNextPage() {
      this.searchParams.offset += this.searchParams.limit;
      this.searchGroup();
    },
    goToLastPage() {
      this.searchParams.offset = this.searchParams.limit * (this.totalPages - 1);
      this.searchGroup();
    },
    goToEditPage(id) {
      this.$router.push({
        name: 'settings.groups.edit',
        params: { groupId: id },
      });
    },
    resetSearchInput() {
      this.searchInput = '';
      this.findAll(this.searchParams).then((response) => {
        this.users = response.groups;
      });
    },
    deleteSelected() {
      let promises = [];
      for (let i = 0; i < this.selected.length; i++) {
        promises.push(this.destroy(this.selected[i].id));
      }
      Promise.all(promises)
        .then(() => {
          this.searchGroup(this.searchParams);
          this.selected = [];
        });
    },
    searchGroup() {
      this.search(this.searchParams).then((response) => {
        this.totalCount = response.nbGroups;
        this.groups = response.groups;
        this.totalPages = this.pagination.totalPages;
      });
    },
    searchGroupBy() {
      this.searchParams.offset = 0;
      this.searchParams.params = [{
        paramName: 'token', paramValue: this.searchInput,
      }];
      this.search(this.searchParams).then((response) => {
        this.totalCount = response.nbGroups;
        this.groups = response.groups;
        this.totalPages = this.pagination.totalPages;
      });
    },
    onClear() {
      this.searchParams.params = [];
      this.searchInput = '';
      this.searchGroup();
    },
    filterOnColumn(filter, filterValue) {
      filter.value = filterValue;
      this.addFilter(filter);
      this.availableFilters.name.searchFieldValue = '';
    },
    addFilter(filterToAdd) {
      this.filters = this.filters.filter(filter => filter.name !== filterToAdd.name);
      this.filters.push(filterToAdd);
      this.searchOnFilters();
    },
    removeFilter(filterToRemove) {
      this.filters = this.filters.filter(filter => filter.name !== filterToRemove.name);
      this.searchOnFilters();
    },
    resetFilters() {
      this.filters = [];
      this.searchOnFilters();
    },
    searchOnFilters() {
      this.searchParams.orders = [{ fieldName: 'name', orderBy: 'asc' }];
      this.searchParams.params = [];
      this.filters.forEach(filter => {
        this.searchParams.params.push({
          paramName: filter.searchParamName,
          paramValue: filter.value,
        });
      });
      this.searchGroup(this.searchParams);
    },
  },

  /**
   * This method will be fired once the application has been mounted.
   */
  mounted() {
    this.fetchGroups();
  },
};
</script>
