export default {
  /**
   * The name of the component.
   */
  name: 'table-filters',

  data() {
    return {
      filters: [],
    };
  },

  /**
   * The methods the mixin can use.
   */
  methods: {
    addFilter(newFilter, computeFilter, getListing) {
      this.searchIsOn = true;
      let index = -1;
      for (let i = 0; i < this.filters.length; i += 1) {
        if (this.filters[i].field === newFilter.field) {
          index = i;
        }
      }
      if (newFilter.sort.length > 0) {
        this.filters.forEach((filter, i) => {
          filter.sort = [];
          if (filter.searchTags.length === 0) {
            this.filters.splice(i, 1);
          }
        });
      }
      if (index === -1) {
        this.filters.push(newFilter);
      } else {
        this.filters.splice(index, 1, newFilter);
      }
      if (computeFilter !== undefined) {
        computeFilter();
      }
      if (getListing !== undefined) {
        getListing();
      }
    },

    clearFilters(clearSearchParamsFromFilter, getListing) {
      if (clearSearchParamsFromFilter && getListing !== undefined) {
        this.filters.forEach((filter) => {
          clearSearchParamsFromFilter(filter);
        });
        this.resetPagination();
        getListing();
      }
      this.filters = [];
    },

    updateFilter(newFilters, computeFilter, getListing) {
      this.filters = newFilters;
      computeFilter();
      if (getListing !== undefined) {
        getListing();
      }
    },

    deleteFilter(field, clearSearchParamsFromFilter, getListing) {
      const index = this.filters.findIndex((filter) => filter.field === field);
      if (clearSearchParamsFromFilter && getListing !== undefined) {
        clearSearchParamsFromFilter(this.filters[index]);
        this.resetPagination();
        getListing();
      }
      this.filters.splice(index, 1);
    },

    resetPagination() {
      this.searchParams.offset = 0;
      this.currentPage = 1;
    },
  },
};
